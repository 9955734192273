import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/TalkNoteLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Slides`}</strong>{`: `}<a parentName="p" {...{
        "href": "https://matryoshka-code.now.sh"
      }}>{`https://matryoshka-code.now.sh`}</a></p>
    <hr></hr>
    <blockquote>
      <p parentName="blockquote">{`duplication is far cheaper than the wrong abstraction`}</p>
    </blockquote>
    <a href="https://twitter.com/sandimetz">@sandimetz</a>
    <ul>
      <li parentName="ul">{`John Carmack on inlined code
`}<a parentName="li" {...{
          "href": "http://number-none.com/blow/john_carmack_on_inlined_code.html"
        }}>{`http://number-none.com/blow/john_carmack_on_inlined_code.html`}</a></li>
      <li parentName="ul">{`ch-athens on Matryoshka Code `}<a parentName="li" {...{
          "href": "http://betabug.ch/blogs/ch-athens/1352"
        }}>{`http://betabug.ch/blogs/ch-athens/1352`}</a></li>
      <li parentName="ul">{`Kent C. Dodds on avoiding hasty abstractions
`}<a parentName="li" {...{
          "href": "https://kentcdodds.com/blog/aha-programming"
        }}>{`https://kentcdodds.com/blog/aha-programming`}</a></li>
    </ul>
    <h3 {...{
      "id": "pitch",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#pitch",
        "aria-label": "pitch permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Pitch`}</h3>
    <p><img parentName="p" {...{
        "src": "https://images-na.ssl-images-amazon.com/images/I/5186qD8N%2BpL._SX466_.jpg",
        "alt": "matryoshkas"
      }}></img></p>
    <p>{`I’m gonna rant about matryoshka code, DRY as dunes of Arrakis and utmost
unpleasant to work with. Have you heard about it? Are you `}<em parentName="p">{`guilty`}</em>{` of
writing it? I’m going to describe the problem and propose few ideas of how
to deal with it.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      